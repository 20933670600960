import { OperationVariables, gql } from '@apollo/client'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import { useSingleMutation } from 'd2/hooks/useMutation'
import type {
  CountriesQuery,
  GenerateSlugMutation,
  GenerateSlugMutationVariables,
  ObserveUser,
  ObserveUserVariables,
  PresignedUrls,
  PresignedUrlsVariables,
  ResendWelcomeInvitation,
  ResendWelcomeInvitationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ToggleOtpMfa,
  ToggleOtpMfaVariables,
  UpdateAlbumLinks,
  UpdateAlbumLinksVariables,
  UpdateAlbumPartnerData,
  UpdateAlbumPartnerDataVariables,
  UserAccountDetails,
  UserHasHiddenPlan,
  UserNeedsOtp,
  VerifyOTPCode,
  VerifyOTPCodeVariables,
} from 'd2/queries'
import type { DocumentNode } from 'd2/types'
import type { MutationReducerArg } from 'd2/hooks/useMutation'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'

export const downgradeStorageTierMutation: DocumentNode = gql`
  mutation DowngradeStorageTier($input: DowngradeStorageTierInput!){
    downgradeStorageTier(input: $input){
      subscription {
        id
        downgrade_to_storage_tier_id
      }
      errors {
        key
        messages
      }
    }
  }
`

export const presignedUrlsQuery: DocumentNode = gql`
  mutation PresignedUrls($input: PresignedUrlInput!) {
    presignedUrl(input: $input) {
      presigned_urls {
        id
        presigned_url
        path
        order
      }
      errors {
        key
        messages
      }
    }
  }
`

const generatePresignedUrlsReducer = ({ data }: MutationReducerArg<PresignedUrls, PresignedUrlsVariables>) => data?.presignedUrl // TODO: Rename the actual mutation to presignedUrls.
export function useGeneratePresignedUrls () {
  return useSingleMutation<PresignedUrls, PresignedUrlsVariables, typeof generatePresignedUrlsReducer>(presignedUrlsQuery, {
    reducer: generatePresignedUrlsReducer,
  })
}

export const observeUser: DocumentNode = gql`
  mutation ObserveUser($input: ObserveUserInput!) {
    observeUser(input: $input) {
      errors {
        key
        messages
      }
      user {
        id
      }
    }
  }
`

const observeUserReducer = ({ data }: MutationReducerArg<ObserveUser, ObserveUserVariables>) => data?.observeUser
export function useObserveUser () {
  return useSingleMutation<ObserveUser, ObserveUserVariables, typeof observeUserReducer>(observeUser, {
    reducer: observeUserReducer,
  })
}

export const resendWelcomeInvitation: DocumentNode = gql`
  mutation ResendWelcomeInvitation($input: ResendWelcomeInvitationInput!) {
    resendWelcomeInvitation(input: $input) {
      errors {
        key
        messages
      }
    }
  }
`

const resendWelcomeInvitationReducer = ({ data }: MutationReducerArg<ResendWelcomeInvitation, ResendWelcomeInvitationVariables>) => data?.resendWelcomeInvitation
export function useResendWelcomeInvitation () {
  return useSingleMutation<ResendWelcomeInvitation, ResendWelcomeInvitationVariables, typeof resendWelcomeInvitationReducer>(resendWelcomeInvitation, {
    reducer: resendWelcomeInvitationReducer,
  })
}

export const payeeInviteFragment: DocumentNode = gql`
  fragment PayeeInviteFragment on PayeeInvite {
    id
    email
    first_name
    last_name
    payee_id
    payee {
      id
      external_url
      image_url
    }
  }
`

export const mustSignUclaFragment: DocumentNode = gql`
  fragment UserMustSignUcla on User {
    id
    must_sign_ucla
    requires_label_owner_agreement
  }
`

export const releaseHistoryBroadcastFragment: DocumentNode = gql`
  fragment ReleaseHistoryBroadcastFragment on Broadcast {
    id
    video_url
    status
    deploy_at
    distributed_at
    social {
      id
      thumbnail_url(size: "small")
      title
      network {
        id
        name
        deployer_name
      }
    }
    network {
      id
      deployer_name
      name
      image_url
    }
  }
`

export const resendPayeeInviteMutation: DocumentNode = gql`
  mutation ResendPayeeInvite($input: ResendPayeeInviteInput!) {
    resendPayeeInvite(input: $input) {
      payee_invite {...PayeeInviteFragment}
      errors {
        key
        messages
      }
    }
  }
  ${payeeInviteFragment}
`

export const sendRoyaltyCenterPaymentHistoryMutation: DocumentNode = gql`
  mutation SendRoyaltyCenterPaymentHistory($input: SendRoyaltyCenterPaymentHistoryInput!) {
    sendRoyaltyCenterPaymentHistory(input: $input) {
      current_user {
        id
        email
      }
      errors {
        key
        messages
      }
    }
  }
`

export const createPayeeAssetSplitsMutation: DocumentNode = gql`
  mutation CreatePayeeAssetSplits($input: CreatePayeeAssetSplitsInput!) {
    createPayeeAssetSplits(input: $input) {
      errors {
        key
        messages
        resource_type
        resource_id
      }
      payee_asset_splits {
        id
        payee_invite {...PayeeInviteFragment}
      }
    }
  }
  ${payeeInviteFragment}
`

export const updateSeenFeaturesMutation: DocumentNode = gql`
  mutation UpdateSeenFeatures($input: UpdateSeenFeaturesInput!) {
    updateSeenFeatures(input: $input) {
      errors {
        key
        messages
      }
    }
  }
`

const COUNTRIES_QUERY: DocumentNode = gql`
  query CountriesQuery {
    countries {
      id
      name
      code
    }
  }
`

const countriesSelectItems = ({ data }: ReducerArg<CountriesQuery>) => (data?.countries ?? []).map(({ code, name }) => ({
  text: name,
  value: code,
}))

export const useCountriesQuery = () => useQuerySwitch<CountriesQuery, EO, typeof countriesSelectItems>(COUNTRIES_QUERY, {
  reducer: countriesSelectItems,
})

const GenerateAlbumSlugMutation: DocumentNode = gql`
  mutation GenerateSlugMutation($input: GenerateAlbumSlugInput!){
    generateAlbumSlug(input: $input){
      album {
        id
        slug
        smartlink_url
        artist_id
        is_distributed
        has_tracks
        private_url
        slug_updated_at
        smartlinks_subdomain
        extra_links_enabled
        extra_links_title
        extra_links {
          id
          title
          description
          type
          button_text
          order
          url
          file_upload {
            id
            title
          }
        }
        partners {
          id
          failed_at
          is_live
          order
          network {
            id
            name
            social_icon_url_with_fallback
          }
          partner_url
          is_available_for_presave
          link_action
          is_user_added
        }
      }
      errors {
        key
        messages
      }
    }
  }
`

const generateAlbumSlugReducer = ({ data }: MutationReducerArg<GenerateSlugMutation, GenerateSlugMutationVariables>) => data?.generateAlbumSlug

export function useGenerateAlbumSlug () {
  return useSingleMutation<GenerateSlugMutation, GenerateSlugMutationVariables, typeof generateAlbumSlugReducer>(GenerateAlbumSlugMutation, {
    reducer: generateAlbumSlugReducer,
  })
}

const AlbumLinksMutation: DocumentNode = gql`
  mutation UpdateAlbumLinks($input: UpdateAlbumLinksInput!) {
    updateAlbumLinks(input: $input) {
      album {
        id
        extra_links_enabled
        extra_links_title
        extra_links {
          id
          title
          description
          order
          button_text
          type
          file_upload {
            id
            title
          }
        }
        smartlink_url
      }
      errors {
        key
        messages
        resource_id
        resource_type
      }
    }
  }
`

const updateAlbumLinksReducer = ({ data }: MutationReducerArg<UpdateAlbumLinks, UpdateAlbumLinksVariables>) => data?.updateAlbumLinks

export function useUpdateAlbumLinks () {
  return useSingleMutation<UpdateAlbumLinks, UpdateAlbumLinksVariables, typeof updateAlbumLinksReducer>(AlbumLinksMutation, {
    reducer: updateAlbumLinksReducer,
  })
}

const RESET_PASSWORD_MUTATION: DocumentNode = gql`
  mutation ResetPasswordMutation($input: SendResetPasswordEmailInput!){
    sendResetPasswordEmail(input: $input){
      errors {
        key
        messages
      }
    }
  }
`

const reduceSendResetPasswordEmail = ({ data }: MutationReducerArg<ResetPasswordMutation, ResetPasswordMutationVariables>) => data?.sendResetPasswordEmail
export function useResetPassword () {
  return useSingleMutation<ResetPasswordMutation, ResetPasswordMutationVariables, typeof reduceSendResetPasswordEmail>(RESET_PASSWORD_MUTATION, {
    reducer: reduceSendResetPasswordEmail,
  })
}

const UserAccountDetailsQuery: DocumentNode = gql`
  query UserAccountDetails {
    me {
      id
      created_at
      can_edit_details
      first_name
      last_name
      phone
      email
      youtube_connected
      youtube_authentication_url
      has_feature_skip_socials: has_feature(feature: skip_socials)
      has_signed_ucla
      signed_agreements_excluding_terms_and_conditions {
        id
      }
      signed_cla_releases {
        id
      }
    }
    mfa {
      id
      is_admin
      otp_mfa
      otp_mfa_method
    }
    observer_signed_in
  }
`

export const useUserAccountDetailsQuery = () => useQuerySwitch<UserAccountDetails, OperationVariables>(UserAccountDetailsQuery, {
  fetchPolicy: 'cache-and-network',
})

const mutation: DocumentNode = gql`
  mutation ToggleOtpMfa ($input: ToggleOtpMfaInput!) {
    toggleOtpMfa(input: $input) {
      user {
        id
        mfa {
          id
          otp_mfa
        }
      }
      errors {
        key
        messages
      }
    }
  }
`

const toggleOtpMfaReducer = ({ data }: MutationReducerArg<ToggleOtpMfa>) => ({
  errors: data?.toggleOtpMfa.errors,
})

export const useToggleOtpMfaMutation = () => useSingleMutation<ToggleOtpMfa, ToggleOtpMfaVariables, typeof toggleOtpMfaReducer>(mutation, {
  reducer: toggleOtpMfaReducer,
})

export const VerifyOTPMutation = gql`
  mutation VerifyOTPCode($input: VerifyOTPCodeInput!) {
    verifyOTPCode(input: $input) {
      user {
        id
        mfa {
          id
          phone
          otp_mfa
          otp_expired
        }
      }
      errors {
        key
        messages
      }
    }
  }
`

const verifyOTPreducer = ({ data }: MutationReducerArg<VerifyOTPCode, VerifyOTPCodeVariables>) => ({ errors: data?.verifyOTPCode.errors })

export const useVerifyOtpMutation = () => useSingleMutation<VerifyOTPCode, VerifyOTPCodeVariables, typeof verifyOTPreducer>(VerifyOTPMutation, {
  reducer: verifyOTPreducer,
})

const UserNeedsOtpQuery: DocumentNode = gql`
  query UserNeedsOtp {
    user_needs_otp
  }
`

const useUserNeedsOtpReducer = ({ data, error }: ReducerArg<UserNeedsOtp, OperationVariables>) => {
  if (!data && !error) return
  return {
    data,
    error,
  }
}

export const useUserNeedsOtpQuery = () => useQuerySwitch<UserNeedsOtp, OperationVariables, typeof useUserNeedsOtpReducer>(UserNeedsOtpQuery, {
  fetchPolicy: 'cache-and-network',
  reducer: useUserNeedsOtpReducer,
})

const UserHasHiddenPlanQuery: DocumentNode = gql`
  query UserHasHiddenPlan {
    me_or_null {
      id
      has_hidden_payment_plan
    }
  }
`

export const useUserHasHiddenPlanQuery = () => useQuerySwitch<UserHasHiddenPlan, OperationVariables>(UserHasHiddenPlanQuery, {
  fetchPolicy: 'cache-and-network',
})

const updateAlbumPartnerDataMutation: DocumentNode = gql`
  mutation UpdateAlbumPartnerData($input: UpdateAlbumPartnerDataInput!) {
    updateAlbumPartnerData(input: $input) {
      album {
        id
        partners{
          id
          order
          network {
            id
            name
            image_url
          }
          partner_url
          is_available_for_presave
          link_action
        }
      }
      errors {
        key
        messages
        resource_id
        resource_type
      }
    }
  }
`

const updateAlbumPartnerDataReducer = ({ data }: MutationReducerArg<UpdateAlbumPartnerData, UpdateAlbumPartnerDataVariables>) => data?.updateAlbumPartnerData
export function useUpdateAlbumPartnerData () {
  return useSingleMutation<UpdateAlbumPartnerData, UpdateAlbumPartnerDataVariables, typeof updateAlbumPartnerDataReducer>(updateAlbumPartnerDataMutation, {
    reducer: updateAlbumPartnerDataReducer,
  })
}
